import React from "react";
import Layout from "./../components/Layout";
import "./service.scss";

const ServicePage = () => {
  return (
    <Layout>
      <div className="container">
        <p>service-page</p>
      </div>
    </Layout>
  );
};

export default ServicePage;
